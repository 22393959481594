const LANG = {
  EN: 'en-us',
  ES: 'es-mx'
}

const LANGUAGE_COUNTRY_LIST = {
  "en-us": {
    lang: "en",
    href: "https://www.whiteclaw.com/"
  },
  "es-us": {
    lang: "es",
    href: "https://www.whiteclaw.com/es/"
  },
  "en": {
    lang: "en",
    href: "https://www.whiteclaw.com/"
  },
  "es": {
    lang: "es",
    href: "https://www.whiteclaw.com/es/"
  },
  "en-ca": {
    lang: "en",
    href: "https://ca.whiteclaw.com/"
  },
  "fr-ca": {
    lang: "fr",
    href: "https://ca.whiteclaw.com/fr/"
  },
  "en-gb": {
    lang: "gb",
    href: "https://uk.whiteclaw.com/"
  },
  "en-ie": {
    lang: "en",
    href: "https://ie.whiteclaw.com/"
  },
  "nl-nl": {
    lang: "nl",
    href: "https://nl.whiteclaw.com/nl/"
  },
  "en-nl": {
    lang: "en",
    href: "https://nl.whiteclaw.com/"
  },
  "en-be": {
    lang: "en",
    href: "https://be.whiteclaw.com/"
  },
  "fr-be": {
    lang: "fr",
    href: "https://be.whiteclaw.com/fr/"
  },
  "nl-be": {
    lang: "nl",
    href: "https://be.whiteclaw.com/nl/"
  },
  "en-fi": {
    lang: "en",
    href: "https://fi.whiteclaw.com/"
  },
  "de-at": {
    lang: "de",
    href: "https://at.whiteclaw.com/"
  },
  "en-at": {
    lang: "en",
    href: "https://at.whiteclaw.com/en/"
  },
  "fr-ch": {
    lang: "fr",
    href: "https://ch.whiteclaw.com/fr/"
  },
  "de-ch": {
    lang: "de",
    href: "https://ch.whiteclaw.com/"
  },
  "en-ch": {
    lang: "en",
    href: "https://ch.whiteclaw.com/en/"
  },
  "de-de": {
    lang: "de",
    href: "https://de.whiteclaw.com/"
  },
  "en-de": {
    lang: "en",
    href: "https://de.whiteclaw.com/en/"
  },
  "sv-se": {
    lang: "sv",
    href: "https://se.whiteclaw.com/sv/"
  },
  "en-se": {
    lang: "en",
    href: "https://se.whiteclaw.com/"
  },
  "en-au": {
    lang: "en",
    href: "https://au.whiteclaw.com/"
  },
  "de": {
    lang: "de",
    href: "https://de.whiteclaw.com/"
  },
  "se": {
    lang: "se",
    href: "https://se.whiteclaw.com/"
  },
  "fi": {
    lang: "en",
    href: "https://fi.whiteclaw.com/"
  },
  "be": {
    lang: "en",
    href: "https://be.whiteclaw.com/"
  },
  "fr": {
    lang: "fr",
    href: "https://be.whiteclaw.com/fr/"
  },
  "sv": {
    lang: "sv",
    href: "https://se.whiteclaw.com/sv/"
  },
  "nl": {
    lang: "nl",
    href: "https://nl.whiteclaw.com/nl/"
  },
}

const HREFLANG_ALT = {
  "homepage": [
    { lang: "en-us", href: "https://www.whiteclaw.com/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es" },
    { lang: "en", href: "https://www.whiteclaw.com/" },
    { lang: "es", href: "https://www.whiteclaw.com/es" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en" },
    { lang: "de-de", href: "https://de.whiteclaw.com/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv" },
    { lang: "en-se", href: "https://se.whiteclaw.com/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr" },
    { lang: "de", href: "https://de.whiteclaw.com/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl" },
    { lang: "x-default", href: "https://www.whiteclaw.com/" },
  ],
  "unsubscribe": [
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/unsubscribe/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/unsubscribe/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/unsubscribe/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/unsubscribe/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/unsubscribe/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/unsubscribe/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/unsubscribe/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/unsubscribe/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/unsubscribe/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/unsubscribe/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/unsubscribe/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/unsubscribe/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/unsubscribe/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/unsubscribe/" },
    { lang: "en", href: "https://www.whiteclaw.com/unsubscribe/" },
    { lang: "de", href: "https://de.whiteclaw.com/unsubscribe/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/unsubscribe/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/unsubscribe/" },
    { lang: "fr", href: "https://ch.whiteclaw.com/fr/unsubscribe/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/unsubscribe/" },
  ],
  "our-story": [
    { lang: "en", href: "https://www.whiteclaw.com/our-story/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/our-story/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/ourstory/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/our-story/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/our-story/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/our-story/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/our-story/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/our-story/" },
    {lang: "en-at", href:"https://at.whiteclaw.com/en/our-story/"},
    {lang: "de-at", href:"https://at.whiteclaw.com/our-story/"},
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/our-story/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/our-story/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/our-story/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/our-story/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/our-story/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/our-story/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/our-story/" },
  ],
  "our-vodka-story": [
    { lang: "en", href: "https://www.whiteclaw.com/our-vodka-story/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/new-products/our-vodka-story/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/new-products/our-vodka-story/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/our-vodka-story/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/new-products/our-vodka-story/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/new-products/our-vodka-story/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/new-products/our-vodka-story/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/our-vodka-story/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/our-vodka-story/" },
  ],
  "subscribe": [
    { lang: "en-au", href: "https://au.whiteclaw.com/subscribe/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/subscribe/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/subscribe/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/subscribe/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/subscribe/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/subscribe/" },
    { lang: "de", href: "https://de.whiteclaw.com/subscribe/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/subscribe/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/subscribe/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/subscribe/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/subscribe/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/subscribe/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/subscribe/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/subscribe/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/subscribe/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/subscribe/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/subscribe/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/subscribe/" },
    { lang: "en", href: "https://www.whiteclaw.com/subscribe/" },
    { lang: "fr", href: "https://ch.whiteclaw.com/fr/subscribe/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/subscribe/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/subscribe/" },
  ],
  "contact-us": [
    { lang: "en", href: "https://www.whiteclaw.com/contact-us/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/contact-us/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/contact-us/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/contact-us/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/contact-us/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/contact-us/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/contact-us/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/contact-us/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/contact-us/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/contact-us/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/contact-us/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/contact-us/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/contact-us/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/contact-us/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/contact-us/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/contact-us/" },
    { lang: "de", href: "https://de.whiteclaw.com/contact-us/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/contact-us/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/contact-us/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/contact-us/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/contact-us/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/contact-us/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/contact-us/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/contact-us/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/contact-us/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/contact-us/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/contact-us/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/contact-us/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/contact-us/" },
  ],
  "products": [
    { lang: "en", href: "https://www.whiteclaw.com/products/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/products/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/" },
    { lang: "es", href: "https://www.whiteclaw.com/products/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/products/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/" },
  ],
  "events": [
    { lang: "en-au", href: "https://au.whiteclaw.com/events/" },
    { lang: "en", href: "https://www.whiteclaw.com/events/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/events/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/events/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/events/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/events/" },
  ],
  "dsar-request": [
    { lang: "en", href: "https://www.whiteclaw.com/dsar-request/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/dsar-request/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/dsar-request/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/dsar-request/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/dsar-request/" },
  ],
  "locator": [
    { lang: "en", href: "https://www.whiteclaw.com/locator/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/locator/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/locator/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/locator/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/where-to-buy/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/where-to-buy/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/where-to-buy/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/locator/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/locator/" },
  ],
  "faq": [
    { lang: "en-au", href: "https://au.whiteclaw.com/faq/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/faq/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/faq/" },
    { lang: "en", href: "https://www.whiteclaw.com/faq/" },
    { lang: "es", href: "https://www.whiteclaw.com/faq/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/faq/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/faq/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/faq/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/faq/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/faq/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/faq/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/faq/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/faq/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/faq/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/faq/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/faq/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/faq/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/faq/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/faq/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/faq/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/faq/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/faq/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/faq/" },
    { lang: "de", href: "https://de.whiteclaw.com/faq/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/faq/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/faq/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/faq/" },
    { lang: "en-gb", href: "https://uksupport.whiteclaw.com/support/home/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/faq/" },
  ],
  "terms-and-conditions": [
    { lang: "en-au", href: "https://au.whiteclaw.com/terms-and-conditions/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/terms-and-conditions/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/terms-and-conditions/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/terms-and-conditions/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/terms-and-conditions/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/terms-and-conditions/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/terms-and-conditions/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/terms-and-conditions/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/terms-and-conditions/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/terms-and-conditions/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/terms-and-conditions/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/terms-and-conditions/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/terms-and-conditions/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/terms-and-conditions/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/terms-and-conditions/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/terms-and-conditions/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/terms-and-conditions/" },
    { lang: "de", href: "https://de.whiteclaw.com/en/terms-and-conditions/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/terms-and-conditions/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/terms-and-conditions/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/terms-and-conditions/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/terms-and-conditions/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/terms-and-conditions/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/terms-and-conditions/" },
    { lang: "en", href: "https://www.whiteclaw.com/terms-and-conditions/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/terms-and-conditions/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/terms-and-conditions/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/terms-and-conditions/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/terms-and-conditions/" },
  ],
  "privacy": [
    { lang: "en", href: "https://www.whiteclaw.com/privacy/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/privacy/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/privacy-policy/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/privacy/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/privacy/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/privacy/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/privacy/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/privacy/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/privacy/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/privacy/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/privacy/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/privacy/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/privacy/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/privacy/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/privacy/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/privacy/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/privacy/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/privacy/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/privacy/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/privacy/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/privacy/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/privacy/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/privacy/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/privacy/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/privacy/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/privacy/" },
    { lang: "de", href: "https://de.whiteclaw.com/privacy/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/privacy/" },
  ],
  "careers-help": [
    { lang: "en-us", href: "https://www.whiteclaw.com/careers-help/" },
    { lang: "en", href: "https://www.whiteclaw.com/careers-help/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/careers-help/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/careers-help/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/careers-help/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/careers-help/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/careers-help/" },
  ],
  "social": [
    { lang: "en-us", href: "https://www.whiteclaw.com/social/" },
    { lang: "en", href: "https://www.whiteclaw.com/social/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/social/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/social/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/social/" },
  ],
  "third-party-cookies": [
    { lang: "en-us", href: "https://www.whiteclaw.com/third-party-cookies/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/pages/third-party-cookies/" },
    { lang: "en", href: "https://www.whiteclaw.com/third-party-cookies/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/third-party-cookies/" },
  ],
  "mango": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/mango/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/mango/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/mango/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/mango/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/flavours/mango/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/flavours/mango/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/mango/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/products/white-claw-hard-seltzer-mango/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/flavours/mango/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/flavours/mango/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/flavours/mango/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/flavours/mango/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/flavours/mango/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/flavours/mango/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/flavours/mango/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/flavours/mango/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/flavours/mango/" },
    { lang: "sv", href: "https://se.whiteclaw.com/sv/flavours/mango/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/flavours/mango/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/mango/" },
    { lang: "de", href: "https://de.whiteclaw.com/flavours/mango/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/mango/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/mango/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/mango/" },
  ],
  "black-cherry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/black-cherry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/black-cherry/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/products/white-claw-black-cherry/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/flavours/black-cherry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/black-cherry/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/flavours/black-cherry/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/flavours/black-cherry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/black-cherry/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/flavours/black-cherry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/black-cherry/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/flavours/black-cherry/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/flavours/black-cherry/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/flavours/black-cherry/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/flavours/black-cherry/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/flavours/black-cherry/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/flavours/black-cherry/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/flavours/black-cherry/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/flavours/black-cherry/" },
    { lang: "de", href: "https://de.whiteclaw.com/flavours/black-cherry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/black-cherry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/black-cherry/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/flavours/black-cherry/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/flavours/black-cherry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/black-cherry/" },
  ],
  "watermelon": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/watermelon/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/watermelon/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/watermelon/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/watermelon/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/watermelon/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/watermelon/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/watermelon/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/watermelon/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/watermelon/" },
  ],
  "natural-lime": [
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/natural-lime/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/natural-lime/" },
    { lang: "de-de", href: "https://de.whiteclaw.com/flavours/natural-lime/" },
    { lang: "en-de", href: "https://de.whiteclaw.com/en/flavours/natural-lime/" },
    { lang: "de", href: "https://de.whiteclaw.com/flavours/natural-lime/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/products/natural-lime/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/natural-lime/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/natural-lime/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/natural-lime/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/natural-lime/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/natural-lime/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/products/white-claw-hard-seltzer-natural-lime/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/flavours/natural-lime/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/flavours/natural-lime/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/flavours/natural-lime/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/flavours/natural-lime/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/flavours/natural-lime/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/flavours/natural-lime/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/flavours/natural-lime/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/flavours/natural-lime/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/flavours/natural-lime/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/flavours/natural-lime/" },
    { lang: "de-ch", href: "https://ch.whiteclaw.com/flavours/natural-lime/" },
    { lang: "fr-ch", href: "https://ch.whiteclaw.com/fr/flavours/natural-lime/" },
    { lang: "en-ch", href: "https://ch.whiteclaw.com/en/flavours/natural-lime/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/natural-lime/" },
     ],
  "raspberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/raspberry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/raspberry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/raspberry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/raspberry/" },
    { lang: "en-gb", href: "https://uk.whiteclaw.com/products/white-claw-hard-seltzer-raspberry/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/flavours/raspberry/" },
    { lang: "en-nl", href: "https://nl.whiteclaw.com/flavours/raspberry/" },
    { lang: "en-be", href: "https://be.whiteclaw.com/flavours/raspberry/" },
    { lang: "nl-be", href: "https://be.whiteclaw.com/nl/flavours/raspberry/" },
    { lang: "fr-be", href: "https://be.whiteclaw.com/fr/flavours/raspberry/" },
    { lang: "en-fi", href: "https://fi.whiteclaw.com/flavours/raspberry/" },
    { lang: "de-at", href: "https://at.whiteclaw.com/flavours/raspberry/" },
    { lang: "en-at", href: "https://at.whiteclaw.com/en/flavours/raspberry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/raspberry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/raspberry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/raspberry/" },
    { lang: "nl-nl", href: "https://nl.whiteclaw.com/nl/flavours/raspberry/" },
    { lang: "nl", href: "https://nl.whiteclaw.com/nl/flavours/raspberry/" },
    { lang: "de", href: "https://at.whiteclaw.com/flavours/raspberry/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/raspberry/" },
    { lang: "sv-se", href: "https://se.whiteclaw.com/sv/flavours/raspberry/" },
    { lang: "en-se", href: "https://se.whiteclaw.com/flavours/raspberry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/raspberry/" },
  ],
  "ruby-grapefruit": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/ruby-grapefruit/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/ruby-grapefruit/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/ruby-grapefruit/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/ruby-grapefruit/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/ruby-grapefruit/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/ruby-grapefruit/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/ruby-grapefruit/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/ruby-grapefruit/" },
  ],
  "passion-fruit": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/passion-fruit/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/passion-fruit/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/passion-fruit/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/passion-fruit/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/passion-fruit/" },
  ],
  "tangerine": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/tangerine/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/tangerine/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/tangerine/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/tangerine/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/tangerine/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/tangerine/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/tangerine/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/tangerine/" },
  ],
  "lemon": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/lemon/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/lemon/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/lemon/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/lemon/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/lemon/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/lemon/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/lemon/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/lemon/" },
  ],
  "blackberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/blackberry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/blackberry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/blackberry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/blackberry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/blackberry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/blackberry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/blackberry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/blackberry/" },
  ],
  "strawberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/strawberry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/strawberry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/strawberry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/strawberry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/strawberry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/strawberry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/strawberry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/strawberry/" },
  ],
  "pineapple": [
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/pineapple/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/pineapple/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/pineapple/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/pineapple/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/pineapple/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/products/pineapple/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/pineapple/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/pineapple/" },
    { lang: "en-ie", href: "https://ie.whiteclaw.com/flavours/pineapple/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/pineapple/" },
  ],
  "peach": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/peach/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/peach/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/peach/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/peach/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/peach/" },
  ],
  "variety-pack-flavor-collection-1": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-1-12-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-1-12-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-1-12-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-1-12-pack/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/variety-pack-flavour-collection-1-12-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/variety-pack-flavor-collection-1-12-pack/" },
  ],
  "variety-pack-flavor-collection-2": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-2-12-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-2-12-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-2-12-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-2-12-pack/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/variety-pack-flavour-collection-2-12-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/variety-pack-flavor-collection-2-12-pack/" },
  ],
  "variety-pack-flavor-collection-3": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-3-12-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/variety-pack-flavor-collection-3-12-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-3-12-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/variety-pack-flavor-collection-3-12-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/variety-pack-flavor-collection-3-12-pack/" },
  ],
  "variety-pack": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/variety-24-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/variety-24-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/variety-24-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/variety-24-pack/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/variety-pack-24-pack/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/variety-pack-24-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/variety-24-pack/" },
  ],

  "vodka-black-cherry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-black-cherry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-black-cherry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-black-cherry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-black-cherry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/vodka-black-cherry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/vodka-black-cherry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/vodka-black-cherry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-black-cherry/" },
  ],
  "vodka-mango": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-mango/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-mango/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-mango/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-mango/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/vodka-mango/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/vodka-mango/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/vodka-mango/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-mango/" },
  ],
  "premium-vodka": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/premium-vodka/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/premium-vodka/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/premium-vodka/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/premium-vodka/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/premium-vodka/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/premium-vodka/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/premium-vodka/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/premium-vodka/" },
  ],
  "vodka-pineapple": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-pineapple/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-pineapple/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-pineapple/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/vodka-pineapple/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-pineapple/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/vodka-pineapple/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/vodka-pineapple/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-pineapple/" },
  ],
  "tropical-pomelo-smash": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surf-tropical-pomelo-smash/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surf-tropical-pomelo-smash/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/surf-tropical-pomelo-smash/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surf-tropical-pomelo-smash/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surf-tropical-pomelo-smash/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surf-tropical-pomelo-smash/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/surf-tropical-pomelo-smash/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surf-tropical-pomelo-smash/" },
  ],
  "citrus-yuzu-smash": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surf-citrus-yuzu-smash/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surf-citrus-yuzu-smash/" },
    { lang: "en-es", href: "https://www.whiteclaw.com/es/products/surf-citrus-yuzu-smash/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surf-citrus-yuzu-smash/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surf-citrus-yuzu-smash/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surf-citrus-yuzu-smash/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/surf-citrus-yuzu-smash/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surf-citrus-yuzu-smash/" },
  ],
  "watermelon-lime-smash": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surf-watermelon-lime-smash/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surf-watermelon-lime-smash/" },
    { lang: "en-es", href: "https://www.whiteclaw.com/es/products/surf-watermelon-lime-smash/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surf-watermelon-lime-smash/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surf-watermelon-lime-smash/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surf-watermelon-lime-smash/" },
    {lang:"fr", href:"https://ca.whiteclaw.com/fr/products/surf-watermelon-lime-smash/"},
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surf-watermelon-lime-smash/" },
  ],
  "wildberry-acai-smash": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surf-wildberry-acai-smash/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surf-wildberry-acai-smash/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/surf-wildberry-acai-smash/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surf-wildberry-acai-smash/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surf-wildberry-acai-smash/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surf-wildberry-acai-smash/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/surf-wildberry-acai-smash/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surf-wildberry-acai-smash/" },
  ],
  "surf-variety-pack": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surf-variety-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surf-variety-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/surf-variety-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/products/surf-variety-pack/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surf-variety-pack/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surf-variety-pack/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/surf-variety-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surf-variety-pack/" },
  ],
  "surge-blood-orange": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/blood-orange-surge/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/blood-orange-surge/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/blood-orange-surge/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/blood-orange-surge/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/blood-orange-surge/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/blood-orange-surge/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/blood-orange-surge/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/blood-orange-surge/" },
  ],
  "surge-natural-lime": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/cranberry-surge/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/cranberry-surge/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/cranberry-surge/" },
    { lang: "es", href: "https://www.whiteclaw.com/products/cranberry-surge/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/cranberry-surge/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/cranberry-surge/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/cranberry-surge/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/cranberry-surge/" },
  ],
  "surge-blackberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/blackberry-surge/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/blackberry-surge/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/blackberry-surge/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/blackberry-surge/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/blackberry-surge/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/blackberry-surge/" },
  ],
  "surge-natural-lime": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surge-natural-lime/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surge-natural-lime/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/surge-natural-lime/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surge-natural-lime/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surge-natural-lime/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surge-natural-lime/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/natural-lime-surge/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surge-natural-lime/" },
  ],
  "surge-cranberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/cranberry-surge/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/cranberry-surge/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/cranberry-surge/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/cranberry-surge/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/cranberry-surge/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/cranberry-surge/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/cranberry-surge/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/cranberry-surge/" },
  ],

  "surge-variety-pack": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/surge-variety-pack-no-1-12-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/surge-variety-pack-no-1-12-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/surge-variety-pack" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/surge-variety-pack" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/surge-variety-pack/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/surge-variety-pack/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/surge-variety-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/surge-variety-pack-no-1-12-pack/" },
  ],
  "lemonade-limón": [
    { lang: "en-us", href: "https://au.whiteclaw.com/products/lemonade-limon/" },
    { lang: "en", href: "https://au.whiteclaw.com/products/lemonade-limon/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/limon-refrshr/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-limon/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-limon/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/lemonade-limon/" },
  ],
  "lemonade-blood-orange": [
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/bloodorange-refrshr/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/lemonade-blood-orange/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/products/lemonade-blood-orange/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-blood-orange/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-blood-orange/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/lemonade-blood-orange/" },
  ],
   "lemonade-blackberry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/lemonade-blackberry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/lemonade-blackberry/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/blackberry-refrshr/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/lemonade-blackberry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/lemonade-blackberry/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/products/lemonade-blackberry/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/products/lemonade-blackberry/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/products/lemonade-blackberry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/lemonade-blackberry/" },
  ],
  "lemonade-strawberry": [
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/strawberry-refrshr/" },
    { lang: "en-us", href: "https://www.whiteclaw.com/products/lemonade-strawberry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-strawberry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/lemonade-strawberry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-strawberry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/lemonade-strawberry/" },
  ],
   "refreshr-lemonade-variety-pack": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/refrshr-lemonade-12-pack/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/refrshr-lemonade-12-pack/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-12-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/refrshr-lemonade-12-pack/" },
    { lang: "en-au", href: "https://au.whiteclaw.com/flavours/refrshr-variety-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/refrshr-lemonade-12-pack/" },
  ],
   "vodka-+-soda-watermelon": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-soda-watermelon/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-soda-watermelon/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-soda-watermelon/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-soda-watermelon/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-soda-watermelon/" },
  ],
  "vodka-+-soda-wild-cherry": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-soda-wild-cherry/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-soda-wild-cherry/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-soda-wild-cherry/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-soda-wild-cherry/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-soda-wild-cherry/" },

  ], "vodka-+-soda-peach": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-soda-peach/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-soda-peach/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-soda-peach/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-soda-peach/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-soda-peach/" },

  ], "vodka-+-soda-pineapple": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-soda-pineapple/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-soda-pineapple/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-soda-pineapple/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-soda-pineapple/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-soda-pineapple/" },
  ],
   "spirits-vodka-+-soda-variety-pack": [
    { lang: "en-us", href: "https://www.whiteclaw.com/products/vodka-soda-variety-pack-no-1/" },
    { lang: "en", href: "https://www.whiteclaw.com/products/vodka-soda-variety-pack-no-1/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/products/vodka-soda-variety-pack/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/products/vodka-soda-variety-pack/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/products/vodka-soda-variety-pack-no-1/" },
  ],
  "thank-you-wave": [
    { lang: "en-us", href: "https://www.whiteclaw.com/thank-you-wave/" },
    { lang: "en", href: "https://www.whiteclaw.com/thank-you-wave/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/thank-you-wave/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/thank-you-wave/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/thank-you-wave/" },
  ],
  "the-difference-is-clear":[
    { lang: "en-us", href: "https://www.whiteclaw.com/the-difference-is-clear/" },
    { lang: "en", href: "https://www.whiteclaw.com/the-difference-is-clear/" },
    { lang: "es-us", href: "https://www.whiteclaw.com/es/the-difference-is-clear/" },
    { lang: "es", href: "https://www.whiteclaw.com/es/the-difference-is-clear/" },
    { lang: "en-ca", href: "https://ca.whiteclaw.com/the-difference-is-clear/" },
    { lang: "fr-ca", href: "https://ca.whiteclaw.com/fr/the-difference-is-clear/" },
    { lang: "fr", href: "https://ca.whiteclaw.com/fr/the-difference-is-clear/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/the-difference-is-clear/" },
  ],
  "tombogo":[
    { lang: "en-us", href: "https://www.whiteclaw.com/whiteclawxtombogo/" },
    { lang: "en", href: "https://www.whiteclaw.com/whiteclawxtombogo/" },
    { lang: "x-default", href:"https://www.whiteclaw.com/whiteclawxtombogo/" },
  ]

}


export { LANG, HREFLANG_ALT, LANGUAGE_COUNTRY_LIST }